const MainDataListOptions = [
  {
    field: 'status',
    labelKey: 'modules.projectsPlanning.fieldNames.status',
    type: 'text',
  },
  {
    field: 'offer_type',
    labelKey: 'modules.projectsPlanning.fieldNames.type',
    type: 'text',
  },
  {
    field: 'offered_module_state',
    labelKey: 'modules.projectsPlanning.fieldNames.moduleState',
    type: 'text',
  },
  {
    field: 'calculation_amount',
    labelKey: 'modules.projectsPlanning.fieldNames.budgetForecast',
    type: 'text',
  },
  {
    field: 'permit_applicant',
    labelKey: 'modules.projectsPlanning.fieldNames.permitResponsible',
    type: 'text',
  },
];

const CustomerDataOptions = [
  {
    field: 'company',
    labelKey: 'modules.projectsPlanning.fieldNames.customerCompany',
    type: 'text',
  },
  {
    field: 'customer_nr_sap',
    labelKey: 'modules.projectsPlanning.fieldNames.customerContact',
    type: 'text',
  },
];

const BooleanBadges = [
  {
    field: 'temporary',
    labelKey: 'modules.projectsPlanning.fieldNames.temporary',
  },
  {
    field: 'WKB',
  },
];

const FieldTypes = {
  project_id: 'text',
  temporary: 'boolean',
  WKB: 'boolean',
  buildsystem: 'text',
  unit_count: 'integer',
  type: 'text',
  user_function: 'text',
  budget_forecast: 'text',
  permit_responsible: 'text',
  company: 'text',
  customer_nr_sap: 'text',
  status: 'text-field',
  red_flags: 'text-field',
};

export { MainDataListOptions, CustomerDataOptions, FieldTypes, BooleanBadges };
