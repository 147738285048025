<template>
  <div
    id="gantt_container"
    class="flex-1 overflow-hidden"
    style="border-radius: 10px"
  />
</template>

<script>
import { gantt } from 'dhtmlx-gantt';
import { mapGetters } from 'vuex';
import moment from 'moment';

export default {
  name: 'DhmeProjectsDashboardGanttChart',
  props: {
    projects: {
      type: Array,
      required: true,
      default: () => {
        return [];
      },
    },
  },
  data: () => {
    return {
      ganttTaskId: null,
      zoomConfig: {
        levels: [
          {
            name: 'day',
            scale_height: 27,
            min_column_width: 80,
            scales: [{ unit: 'day', step: 1, format: '%d %M' }],
          },
          {
            name: 'week',
            scale_height: 50,
            min_column_width: 60,
            scales: [
              {
                unit: 'week',
                step: 1,
                format: function (date) {
                  const dateToStr = gantt.date.date_to_str('%d %M');
                  const endDate = gantt.date.add(date, -6, 'day');
                  const weekNum = gantt.date.date_to_str('%W')(date);
                  return (
                    '#' +
                    weekNum +
                    ', ' +
                    dateToStr(date) +
                    ' - ' +
                    dateToStr(endDate)
                  );
                },
              },
              { unit: 'day', step: 1, format: '%j %D' },
            ],
          },
          {
            name: 'month',
            scale_height: 50,
            min_column_width: 120,
            scales: [
              { unit: 'month', format: '%F, %Y' },
              { unit: 'week', format: 'Week #%W' },
            ],
          },
          {
            name: 'quarter',
            height: 50,
            min_column_width: 90,
            scales: [
              { unit: 'month', step: 1, format: '%M' },
              {
                unit: 'quarter',
                step: 1,
                format: function (date) {
                  const dateToStr = gantt.date.date_to_str('%M');
                  const endDate = gantt.date.add(
                    gantt.date.add(date, 3, 'month'),
                    -1,
                    'day'
                  );
                  return dateToStr(date) + ' - ' + dateToStr(endDate);
                },
              },
            ],
          },
          {
            name: 'year',
            scale_height: 50,
            min_column_width: 30,
            scales: [{ unit: 'year', step: 1, format: '%Y' }],
          },
        ],
      },
    };
  },
  computed: {
    ...mapGetters(['project']),
    ...mapGetters({
      moduleData: 'projectsPlanning/data',
      selectedProject: 'projectsPlanning/selectedProject',
      isSidebarDisplayed: 'projectsPlanning/isProjectSidebarDisplayed',
    }),
    tasksData() {
      if (!this.projects?.length) {
        return {
          tasks: [],
        };
      }
      return {
        tasks: this.projects.map((p) => p.chartData),
      };
    },
  },
  watch: {
    tasksData(val) {
      this.parseData(val);
    },
  },
  mounted() {
    this.init();
    this.parseData(this.tasksData);
    this.showDate(new Date());
  },
  beforeDestroy() {
    gantt.clearAll();
    gantt.detachAllEvents();
  },
  methods: {
    init() {
      gantt.ext.zoom.init(this.zoomConfig);
      gantt.ext.zoom.setLevel('month');

      gantt.templates.rightside_text = () => null;
      gantt.templates.leftside_text = () => null;

      gantt.templates.parse_date = function (date) {
        return new Date(date);
      };
      gantt.templates.format_date = function (date) {
        return date.toISOString();
      };

      gantt.templates.progress_text = function (start, end, task) {
        if (task.progress > 0) {
          return (
            "<span style='text-align:left; padding-left: 10px'>" +
            Math.round(task.progress * 100) +
            '% </span>'
          );
        }
        return null;
      };

      gantt.config.columns = [
        { name: 'number', label: '# Number', tree: false, width: '100' },
        { name: 'title', label: 'Name', width: '200' },
      ];

      gantt.config.inherit_scale_class = true;
      gantt.config.work_time = true;
      gantt.config.sort = true;
      gantt.config.readonly = true;
      gantt.config.open_split_tasks = false;
      gantt.config.scroll_size = 10;

      gantt.templates.scale_cell_class = function (date) {
        if (moment(date).isSame(moment(), 'day')) {
          return 'current_date';
        }
      };

      gantt.attachEvent('onTaskRowClick', this.onTaskRowClick);
      gantt.attachEvent('onTaskClick', this.onTaskClick);

      gantt.init('gantt_container');
    },
    setZoomLevel(level) {
      switch (level) {
        case 'year':
        case 'quarter':
        case 'month': {
          gantt.templates.task_text = function (start, end, task) {
            return '';
          };
          break;
        }
        case 'week':
        case 'day': {
          gantt.templates.task_text = function (start, end, task) {
            return task.text;
          };
          break;
        }
      }
      gantt.ext.zoom.setLevel(level);
    },
    parseData(data) {
      gantt.clearAll();
      gantt.parse(data);
      gantt.sort('start_date');
    },
    showDate(data) {
      gantt.showDate(data);
    },
    onTaskClick(id) {
      const task = gantt.getTask(id);
      if (task) {
        if (task.type === 'project') {
          this.onTaskRowClick(task.id);
        } else {
          this.onTaskRowClick(task.parent);
        }
      }
    },
    onTaskRowClick(id) {
      const targetProject = this.projects.find((p) => p.id === id);
      if (targetProject?.id === this.selectedProject?.id) {
        this.$store.commit('projectsPlanning/toggle_project_sidebar');
        return;
      }
      this.$store.commit(
        'projectsPlanning/set_selected_project',
        targetProject
      );
      this.$store.dispatch('projectsPlanning/fetchTeams');
      this.$store.dispatch('projectsPlanning/fetchComments');
      this.$store.commit('projectsPlanning/open_project_sidebar');
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~dhtmlx-gantt/codebase/skins/dhtmlxgantt_material.css';

::v-deep {
  .gantt_task_progress {
    text-align: left;
    box-sizing: border-box;
    color: white;
    font-weight: bold;
  }

  .gantt_task_cell.day-off {
    background-color: #eff5fd;
  }

  .gantt_task_row.gantt_selected .gantt_task_cell.day-off {
    background-color: #eff5fd;
  }

  .gantt_scale_cell {
    user-select: none;

    &.current_date {
      background-color: lightgray;
    }
  }

  .gantt_task_cell {
    &.current_date {
      background-color: lightgray;
    }
  }

  .gantt_tree_indent {
    width: 10px;
  }

  .status {
    background-color: #1de9b6;
  }
}
</style>
