<template>
  <v-card
    v-if="isSidebarDisplayed"
    class="d-flex flex-column"
    outlined
    style="width: 500px; max-height: calc(100vh - 46px - 46px - 16px)"
  >
    <v-img :src="projectImage" class="pa-2" max-height="200">
      <div v-if="!projectImage" class="no-image" />
      <div
        class="d-flex align-center pr-2 white--text pos-rel"
        style="z-index: 1"
      >
        <div>
          <h2>
            {{ selectedProject?.number }}
          </h2>
          <h2>
            {{ selectedProject?.name }}
          </h2>
        </div>
        <v-spacer />
        <v-btn
          :loading="isLoading"
          color="white"
          icon
          @click="closeTaskSidebar"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </div>
    </v-img>

    <div
      v-if="selectedProject"
      class="flex-grow-1 pa-2 d-flex flex-column"
      style="height: 0"
    >
      <div v-if="projectPlanningInformation" class="d-flex flex-column py-2">
        <div class="d-flex">
          <v-btn
            v-for="badge in BooleanBadges"
            :key="badge.field"
            :color="
              projectPlanningInformation[badge.field]
                ? 'var(--v-primary-lighten2)'
                : 'var(--v-secondary-lighten5)'
            "
            :disabled="$wait.is('module.project-planning.record.save')"
            class="mr-2"
            elevation="0"
            small
            @click="
              editRecord(!projectPlanningInformation[badge.field], badge.field)
            "
          >
            {{ badge.labelKey ? $t(`${badge.labelKey}`) : badge.field }}
          </v-btn>
          <v-spacer />
          <v-btn color="primary" outlined small @click="openProjectPlanning"
            >Details
          </v-btn>
        </div>
      </div>
      <div class="overflow-y-auto flex-1 pa-2">
        <v-card class="my-2" outlined>
          <v-card-text>
            <div
              class="font-weight-bold d-flex align-center"
              @click="$store.dispatch('projectsPlanning/fetchTeams')"
            >
              {{ $t('modules.projectsPlanning.general') }}
              <v-spacer />
              <v-icon small>mdi-information-box-outline</v-icon>
            </div>
            <div
              v-for="item of MainDataListOptions"
              :key="item.field"
              class="d-flex flex-row px-2"
            >
              <span>{{ $t(`${item.labelKey}`) }}</span>
              <span class="d-flex flex-grow-1 justify-end">
                {{ projectPlanningInformation[item.field] || '-' }}
              </span>
            </div>
          </v-card-text>
        </v-card>

        <v-card class="my-2" outlined>
          <v-card-text>
            <div
              class="font-weight-bold d-flex align-center"
              @click="$store.dispatch('projectsPlanning/fetchTeams')"
            >
              {{ $t('modules.projectsPlanning.customer') }}
              <v-spacer />
              <v-icon small>mdi-account-cash</v-icon>
            </div>
            <div
              v-for="item of CustomerDataOptions"
              :key="item.field"
              class="d-flex flex-row px-2"
            >
              <span>{{ $t(`${item.labelKey}`) }} </span>
              <span class="d-flex flex-grow-1 justify-end">
                {{ projectPlanningInformation[item.field] || '-' }}
              </span>
            </div>
          </v-card-text>
        </v-card>

        <v-card class="my-2" outlined>
          <v-card-text>
            <div
              class="font-weight-bold d-flex align-center"
              @click="$store.dispatch('projectsPlanning/fetchTeams')"
            >
              {{ $t('modules.projectsPlanning.roles') }}
              <v-spacer />
              <v-icon small>mdi-account-key</v-icon>
            </div>
            <div
              v-for="role in selectedProjectRoles"
              :key="role.id"
              class="d-flex flex-column px-2"
            >
              <span>{{ role.name }}</span>
              <div>
                <v-chip
                  v-for="user in role.users.map((u) => u.name)"
                  :key="user"
                  class="mr-1"
                  x-small
                  >{{ user }}
                </v-chip>
              </div>
            </div>
          </v-card-text>
        </v-card>

        <v-card class="my-2" outlined>
          <v-card-text>
            <div class="font-weight-bold d-flex align-center mb-2">
              {{ $t('modules.projectsPlanning.progress') }}
              <v-spacer />
              <v-icon small>mdi-progress-check</v-icon>
            </div>
            <div class="overflow-y-auto py-2" style="max-height: 300px">
              <task-progress
                v-for="task in projectRootTasks"
                :key="task.id"
                :task="task"
                :task-progress="task.progress"
              />
            </div>
          </v-card-text>
        </v-card>

        <!--        <v-card outlined class="my-2" height="144px">-->
        <!--          <v-card-text>-->
        <!--            <div class="font-weight-bold mb-2 d-flex align-center">-->
        <!--              {{ $t('modules.projectsPlanning.redFlags') }}-->
        <!--              <v-spacer />-->
        <!--              <v-icon small color="error">mdi-flag</v-icon>-->
        <!--            </div>-->
        <!--            <v-textarea-->
        <!--              v-model="projectPlanningInformation['red_flags']"-->
        <!--              hide-details-->
        <!--              filled-->
        <!--              height="80"-->
        <!--              dense-->
        <!--              @change="editRecord($event, 'red_flags')"-->
        <!--            />-->
        <!--          </v-card-text>-->
        <!--        </v-card>-->

        <v-card class="my-2" outlined>
          <v-card-text>
            <div class="font-weight-bold mb-2 d-flex align-center">
              {{ $t('modules.projectsPlanning.comments') }}
              <v-spacer />
              <v-icon small>mdi-message-text</v-icon>
            </div>
            <v-timeline dense>
              <v-timeline-item
                v-for="item in selectedProjectComments"
                :key="item.id"
                class="mb-4"
                color="primary"
                small
              >
                <template #icon>
                  <v-tooltip bottom>
                    <template #activator="{ on, attrs }">
                      <v-avatar
                        :color="stringToConstantColor(item.email)"
                        size="30"
                        v-bind="attrs"
                        v-on="on"
                      >
                        <span
                          class="white--text text-h6"
                          style="font-size: 12px !important"
                        >
                          {{
                            item.firstname.charAt(0) + item.lastname.charAt(0)
                          }}
                        </span>
                      </v-avatar>
                    </template>
                    <span>{{ item.email }}</span>
                  </v-tooltip>
                </template>
                <v-row justify="space-between">
                  <v-col cols="7">{{ item.comment }}</v-col>
                  <v-col class="text-right" cols="5"
                    >{{ formatDate(item.timestamp) }}
                  </v-col>
                </v-row>
              </v-timeline-item>
            </v-timeline>
          </v-card-text>
        </v-card>
      </div>
    </div>
    <div v-else class="d-flex justify-center align-center flex-grow-1">
      <ant-loading />
    </div>
  </v-card>
</template>

<script>
import { mapGetters } from 'vuex';
import AntLoading from '@/components/AntLoading';
import TaskProgress from '@/components/Tasks/TaskProgress.vue';
import DataHelper from '@/services/data-helper';
import { updateRecord } from '@/services/api/record.api';
import {
  BooleanBadges,
  CustomerDataOptions,
  FieldTypes,
  MainDataListOptions,
} from '@/modules/daiwa-house-modular-europe/ProjectsDashboard/dhmeProjectsPlanningSidebarConfig';
import { DHME_PROJECT_GANTT } from '@/modules/modules';
import moment from 'moment';
import { stringToConstantColor } from '@/components/Charts/utils/tasks-chart.utils';

export default {
  name: 'DhmeProjectsDashboardSidebar',
  components: {
    TaskProgress,
    AntLoading,
  },
  data: () => ({
    projectImage: null,
  }),
  computed: {
    ...mapGetters(['projects', 'authenticatedUser']),
    ...mapGetters({
      isSidebarDisplayed: 'projectsPlanning/isProjectSidebarDisplayed',
      selectedProject: 'projectsPlanning/selectedProject',
      selectedProjectRoles: 'projectsPlanning/selectedProjectRoles',
      selectedProjectComments: 'projectsPlanning/selectedProjectComments',
      licenseTasks: 'projectsPlanning/licenseTasks',
      data: 'projectsPlanning/data',
    }),
    isLoading() {
      return this.$wait.is('module.project-planning.*');
    },
    projectRootTasks() {
      return this.licenseTasks
        .filter(
          ({ task_project, parent }) =>
            task_project?.id === this.selectedProject?.id && !parent
        )
        .map((task) => {
          let progress;
          if (task.hasChildren) {
            const completedCount = task.children.filter(
              (t) => t.status === 'closed'
            )?.length;
            progress = Math.ceil((completedCount / task.children.length) * 100);
          } else {
            task.status === 'closed' ? (progress = 100) : (progress = 0);
          }
          return {
            ...task,
            progress,
          };
        });
    },
    projectPlanningInformation() {
      const records = this.data?.CFFA_DHME_PROJECT_INTAKE_DATA?.records || [];
      return (
        records.find(
          ({ project_id }) => project_id === this.selectedProject?.id
        ) || {}
      );
    },
    MainDataListOptions() {
      return MainDataListOptions;
    },
    CustomerDataOptions() {
      return CustomerDataOptions;
    },
    FieldTypes() {
      return FieldTypes;
    },
    BooleanBadges() {
      return BooleanBadges;
    },
  },
  watch: {
    async selectedProject(value) {
      this.projectImage = null;
      if (value?.image) {
        this.projectImage = `data:${value.image.mimetype};base64,${value.image.file}`;
      }
    },
  },
  beforeDestroy() {
    this.closeTaskSidebar();
  },
  methods: {
    stringToConstantColor,
    formatDate(timestamp) {
      return moment.unix(timestamp).format('DD-MM-YYYY HH:mm');
    },
    openProjectPlanning() {
      const projectGanttModule = this.selectedProject.modules.find(
        (m) => m.route === DHME_PROJECT_GANTT
      );
      if (projectGanttModule) {
        this.$store.dispatch('setProject', this.selectedProject);
        this.$router.push({
          name: DHME_PROJECT_GANTT,
          params: {
            slug: this.selectedProject.slug,
          },
        });
      } else {
        this.$store.commit('showNotification', {
          content: 'Project gantt module not found',
          color: 'warning',
        });
      }
    },
    closeTaskSidebar() {
      this.$store.commit('projectsPlanning/close_project_sidebar');
    },
    parseRecordBody(record) {
      const masterProject = this.projects.find((p) => p.is_master);
      let body = {
        project: {
          id: masterProject.id,
        },
        table: {
          id: this.data.CFFA_DHME_PROJECT_INTAKE_DATA?.id,
        },
        record: {},
      };

      if (this.$route?.params?.sessionId) {
        body.session = {
          id: this.$route.params.sessionId,
        };
      }
      Object.entries(record).forEach(([key, value]) => {
        if (key === 'id') return;
        body.record[key] = DataHelper.parseData(
          this.FieldTypes[key],
          value,
          false
        );
      });

      return body;
    },

    async editRecord(value, field) {
      if (!this.projectPlanningInformation?.id) {
        return this.$store.commit('showNotification', {
          content: 'The project data not created in master table yet',
          color: 'error',
        });
      }
      const updatedRecord = {
        ...this.projectPlanningInformation,
        [field]: value,
      };
      const body = this.parseRecordBody(updatedRecord);
      try {
        this.$wait.start('module.project-planning.record.save');
        await updateRecord(this.projectPlanningInformation.id, body);
        this.$store.commit('showNotification', {
          content: 'Record updated successfully',
          color: 'success',
        });
        this.$emit('update-data');
      } catch (error) {
        this.$store.commit('showNotification', {
          content: error.message,
          color: 'error',
        });
      } finally {
        this.$wait.end('module.project-planning.record.save');
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.no-image {
  height: 200px;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 0;
  background: linear-gradient(
    45deg,
    var(--v-primary-base) 10%,
    var(--v-primary-lighten3) 60%,
    var(--v-primary-lighten5) 100%
  ) !important;
}
</style>
